import sha256 from 'crypto-js/sha256'

import { useReduxSelector } from '../../hooks'
import { RootState } from '../../modules'

export const mapValuesToHashedSha256 = <V extends string>(original: Record<any, V>) =>
  Object.fromEntries(Object.entries(original).map(([key, value]) => [key, sha256(value).toString()]))

export const filterEmptyValues = <V>(original: Record<any, V | undefined | null>) =>
  Object.fromEntries(
    Object.entries(original).filter(
      ([_, value]) => !(value === undefined || value === null || value === '' || value === 0)
    )
  ) as { [key: string]: V }

export const useOffer = () => {
  const offerState = useReduxSelector((state: RootState) => state.offerState)
  if (
    offerState === undefined ||
    !offerState.initialized ||
    offerState.offer === undefined ||
    offerState.offer.estimated_bill_amount_cent === 0
  ) {
    return undefined
  }

  const { offer } = offerState
  return offer
}

export const useWalletCard = () => {
  const walletState = useReduxSelector((state: RootState) => state.customerWallet)
  if (walletState === undefined || !walletState.initialized) {
    return undefined
  }

  const { defaultCard } = walletState
  return defaultCard
}

export const useProperty = () => {
  const propertyLinking = useReduxSelector((state: RootState) => state.propertyLinking)
  return propertyLinking?.property
}
