import React from 'react'
import { Analytics } from '@genoa/analytics'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { SpinnerLoading } from '../../../../views/components/SpinnerLoading/SpinnerLoading'
import { HeroSocks as HangTightImage } from '../../../assets'
import { Headline1, SmallText, Text } from '../../../components'
import { BasePageLayout } from '../../../layouts'

export const WaitingOffer = () => (
  <BasePageLayout analyticsScreenName={Analytics.Screens.ONBOARDING_WAITING_OFFER}>
    <Box minH={'60px'} />

    <Flex align="center" justify="center">
      <HangTightImage />
    </Flex>

    <Box minH={'18px'} />

    <Container flex={1} flexDirection="column" px="lg" alignItems={'center'}>
      <Headline1>Hang tight</Headline1>

      <Box minH={'24px'} />

      <Text textAlign="center">Please keep this page open while we process your application</Text>

      <Box minH={'24px'} />

      <SmallText textAlign="center" fontWeight={'bold'}>
        This may take a few minutes
      </SmallText>

      <Box minH={'64px'} />

      <Box>
        <SpinnerLoading />
      </Box>
    </Container>
  </BasePageLayout>
)

const Container = styled(Flex)`
  height: 100%;
`
