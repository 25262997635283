import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { useLogger } from '../logger'

type SetState<T> = Dispatch<SetStateAction<T | undefined>>

const loadAirgapJs = async (airgapScriptSrc: string, onLoadEvt: SetState<Event>) => {
  // https://docs.transcend.io/docs/articles/consent-management/configuration/loading-asynchronously
  return new Promise((resolve, reject) => {
    const readyQueue = window?.airgap?.readyQueue || []
    const airgap = (window.airgap = window.airgap || {
      readyQueue,
      ready: (callback: AirgapReadyCallback) => {
        readyQueue.push(callback)
      },
    })

    const head = document.getElementsByTagName('head')[0],
      script = document.createElement('script')

    // add Promise resolution callbacks
    airgap.ready(resolve)
    script.addEventListener('error', reject)

    // initialize script tag and inject into DOM
    script.async = script.defer = true
    script.src = airgapScriptSrc
    script.onload = onLoadEvt
    script.dataset.consentExpiry = '525600'
    script.dataset.csp = 'off'
    script.dataset.defaultRegime = 'US_DNSS'
    script.dataset.dismissedViewStatus = 'Closed'
    script.dataset.privacyPolicy = 'https://getflex.com/privacy/'
    script.dataset.prompt = 'auto'
    script.dataset.protect = 'on'
    script.dataset.regulateAllScripts = 'on'
    script.dataset.regulateCookies = 'on'
    script.dataset.uiAllowInEmbeds = 'off'
    script.dataset.unknownCookiePolicy = 'allow'
    script.dataset.unknownRequestPolicy = 'allow'
    head.append(script)
  })
}

export const useConsentManagement = (
  uid: string | null,
  isAnonymous: boolean,
  shouldLoadLibrary: () => boolean,
  scriptSrc?: string
) => {
  const logger = useLogger('ConsentManagement')

  // boolean indicating whether the Consent Management script was loaded or not
  // null => no script to load, or user isn't logged in, or user is logged in and loadAirgapJs promise is pending
  // true => user is logged in and loadAirgapJs promise successfully resolved; ie: script has loaded and initialized
  // false => user is logged in and either loadAirgapJs promise failed with a rejection or the feature flag is off
  const [isLoaded, setIsLoaded] = useState<boolean | null>(null)

  // to programmatically interact with consent, we need a "trusted" DOM event. for more information, read:
  // https://docs.transcend.io/docs/articles/consent-management/configuration/creating-your-own-ui#consent-authorization
  // this hook surfaces the script onload event captured by this state variable
  const [scriptLoadEvent, setScriptLoadEvent] = useState<Event>()

  useEffect(() => {
    if (scriptSrc === undefined || isLoaded) {
      return
    }

    if (uid !== null && !isAnonymous) {
      if (shouldLoadLibrary()) {
        loadAirgapJs(scriptSrc, setScriptLoadEvent)
          .then(() => setIsLoaded(true))
          .catch((error) => {
            logger.error('loadAirgapJs', error)
            setIsLoaded(false)
          })
      } else {
        setIsLoaded(false)
      }
    }
  }, [scriptSrc, isLoaded, uid, isAnonymous])

  // for a consumer of this hook, Consent Management is considered:
  // - "initialized" when it is safe to load other libraries into the DOM because there was an attempt made to load the script
  // - "loaded" when the Consent Management async script is successfully fetched and the ready stub from the script fires as expected
  return {
    isInitialized: scriptSrc === undefined || isLoaded !== null,
    isLoaded: isLoaded === true,
    scriptLoadEvent,
  }
}
