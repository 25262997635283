import { useAccount } from '../use-account'

// TODO:
// send this biller id with the token data so that we can compare any existing biller
// associated with the account to the biller associate with embed.
const YARDI = 'bv2ae21adeef07c4e6fb5d7a301938cj48f'

export const useShouldRestartEmbedOnboarding = () => {
  const { billerConnection } = useAccount()
  const shouldRestartEmbedOnboarding =
    billerConnection && billerConnection.biller && billerConnection.biller.public_id !== YARDI

  return shouldRestartEmbedOnboarding
}
