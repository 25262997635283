import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import {
  setBillerPropertyDetailsAction,
  setBillerUnitAction,
  setPrefillsIsActiveAction,
  setPrefillsIsLoadingAction,
} from '@genoa/state-management'

import { useAuthState } from '../../contexts'
import { RootState, setContactAction, setRentAmountAction } from '../../modules'
import { BillerResidentLookupData, resetEmbedAction } from '../../modules/flex2/embed'
import { useLazyGetResidentLookupQuery } from '../../modules/flexApi'
import { useAnalytics } from '../../providers'
import * as Routes from '../../routing/constants'
import { useEmbed } from '../embed/use-embed'
import { useReduxAction } from '../use-redux-action'
import { useReduxSelector } from '../use-redux-selector'
import { BillerPropertyDetailsWithLocation } from './biller'
import { useHandleIterableDetails } from './use-handle-iterable-details'

// TODO: revisit flow logic when we decide on fall-through cases
export interface EmbedPrefillRedirects {
  readonly navigateToAddressSelection: () => void
  // readonly navigateToUnitSelection: () => void
  // readonly navigateToConfirmAddress: () => void
  // readonly navigateToRentPortalSignIn: () => void
}

export interface UseEmbedPrefills {
  readonly loadingPropertyPrefills: boolean
  readonly prefillIsActive: boolean
  readonly handlePrefill: (navigators: EmbedPrefillRedirects) => Promise<void>
  readonly handleEmbedAccountCreated: () => void
  readonly resetPrefills: () => void
}

export const useEmbedPrefills = (): UseEmbedPrefills => {
  const analytics = useAnalytics()
  const navigate = useNavigate()
  const { isAnonymous, uid } = useAuthState()
  const handleIterableDetails = useHandleIterableDetails()
  const embed = useEmbed()

  const setPrefillsIsActive = useReduxAction(setPrefillsIsActiveAction)
  const setPrefillsIsLoading = useReduxAction(setPrefillsIsLoadingAction)
  const setBillerUnit = useReduxAction(setBillerUnitAction)
  const setBillerPropertyDetails = useReduxAction(setBillerPropertyDetailsAction)
  const setOnboardingContact = useReduxAction(setContactAction)
  const setRentAmount = useReduxAction(setRentAmountAction)
  const resetEmbed = useReduxAction<void>(resetEmbedAction)

  const isActive = useReduxSelector((state: RootState) => state.prefills.isActive)
  const isLoading = useReduxSelector((state: RootState) => state.prefills.isLoading)

  const [getResidentLookup] = useLazyGetResidentLookupQuery()

  const setResidentBillerAndContactInfo = ({ pmc, property, resident }: BillerResidentLookupData) => {
    const propertyDetails: BillerPropertyDetailsWithLocation = {
      created_at: property.created_at,
      external_pmc_id: pmc.external_pmc_id,
      external_property_id: property.external_property_id,
      identity_biller_id: property.identity_biller_id,
      integration_type: property.integration_type,
      billing_integration_type: property.biller_integration_type,
      location: {
        address_line_1: property.address_line_1,
        address_line_2: property.address_line_2,
        city: property.city,
        latitude: property.latitude,
        longitude: property.longitude,
        postal_code: property.zip,
        state: property.state,
      },
      name: property.name,
      pmc_id: pmc.id,
      pmc_name: pmc.name,
      portal_id: null,
      property_id: property.id,
      public_id: property.public_id,
      status: property.status,
      updated_at: property.updated_at,
      current_tier: null,
      qualified_tier: null,
    }

    const unit = {
      address_line_1: property.address_line_1,
      address_line_2: property.address_line_2,
      city: property.city,
      label: resident.label,
      postal_code: property.zip,
      state: property.state,
      unit: resident.unit,
    }

    const contact = {
      firstName: resident.first_name,
      lastName: resident.last_name,
      email: resident.email,
    }

    analytics.logEvent(Analytics.Events.EMBED_PREFILLS_PROPERTY_SUCC)
    handleIterableDetails(propertyDetails)

    setBillerPropertyDetails(propertyDetails)
    setBillerUnit(unit)
    setOnboardingContact(contact)
    setRentAmount({ amount: (resident.rent_amount_cents / 100).toFixed(2) })
  }

  const tryMatchEmbeddedResident = async () => {
    try {
      const resident = await getResidentLookup({ client: embed.client, token: embed.token }).unwrap()
      setResidentBillerAndContactInfo({ ...resident.data, initialized: true })
      return true
    } catch (error) {
      return false
    }
  }

  const handleRedirectNoMatch = (navigate: () => void) => {
    setPrefillsIsActive(false)
    setPrefillsIsLoading(false)
    navigate()
  }

  const handleRedirectMatch = (navigate: () => void) => {
    setPrefillsIsActive(true)
    navigate()
  }

  const handlePrefillInternal = async (navigators: EmbedPrefillRedirects) => {
    const redirectNoMatch = () => handleRedirectNoMatch(navigators.navigateToAddressSelection)

    const isEmbeddedResidentMatched = await tryMatchEmbeddedResident()
    if (isEmbeddedResidentMatched) {
      // make sure we fire an analytic event on success
      return handleRedirectMatch(() => navigate(Routes.Onboarding.CREATE_ACCOUNT))
    }
    resetEmbed()
    return redirectNoMatch()
  }

  const handlePrefill = useCallback(
    async (navigators: EmbedPrefillRedirects): Promise<void> => {
      if (isAnonymous || !uid) {
        return handleRedirectNoMatch(navigators.navigateToAddressSelection)
      }

      setPrefillsIsLoading(true)
      await handlePrefillInternal(navigators)
      setPrefillsIsLoading(false)
    },
    [uid, isLoading, isAnonymous]
  )

  // when we decide how we are handling fall-through and half prefill embeds we can update the navigation here
  const handleEmbedAccountCreated = useCallback(() => {
    if (isActive) {
      return navigate(Routes.Onboarding.DIRECT_INTEGRATION_CONFIRMATION)
    }
  }, [isActive])

  const resetPrefills = () => {
    analytics.logEvent(Analytics.Events.PREFILLS_RESET)
    setPrefillsIsActive(false)
  }

  return {
    loadingPropertyPrefills: isLoading,
    prefillIsActive: isActive,
    handlePrefill,
    handleEmbedAccountCreated,
    resetPrefills,
  }
}
