import { isMobile } from 'react-device-detect'
import { EMBED_QR_CODE as content } from '@genoa/screen-content'
import { Box, Flex, Image } from '@chakra-ui/react'
import styled from '@emotion/styled'

import QRCodeDownloadApp from '../../assets/images/qr-code-download-app.jpg'
import { AppStoreButton } from '../AppStoreButton'
import { PlayStoreButton } from '../PlayStoreButton'
import { SmallText } from '../Typography'

interface AppStoreProps {
  onClickAppStore?: () => void
  onClickPlayStore?: () => void
}

export const AppStore = (props: AppStoreProps) => (
  <AppButtonContainer>
    {isMobile ? (
      <>
        <StoreButtonContainer>
          <AppStoreButton onClickAppStore={props.onClickAppStore} />
        </StoreButtonContainer>
        <Box minH="24px" />
        <StoreButtonContainer>
          <PlayStoreButton onClickPlayStore={props.onClickPlayStore} />
        </StoreButtonContainer>
      </>
    ) : (
      <QRCodeContainer>
        <QRCodeText>{content.BODY}</QRCodeText>
        <QRCode>
          <QRCodeContainerWrapper>
            <Image src={QRCodeDownloadApp} />
          </QRCodeContainerWrapper>
        </QRCode>
      </QRCodeContainer>
    )}
  </AppButtonContainer>
)

const AppButtonContainer = styled(Flex)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.cloud};
  border-radius: ${({ theme }) => theme.radii.lg};
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => theme.fixedSizes.spacing_100};
  width: 100%;
`

const StoreButtonContainer = styled(Flex)`
  cursor: pointer;
  max-height: ${({ theme }) => theme.components.AppStore.height};
  min-height: ${({ theme }) => theme.components.AppStore.width};
  transform: scale(0.7);
`

const QRCodeContainer = styled(Flex)`
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => theme.fixedSizes.spacing_25};
  text-align: center;
`

const QRCode = styled(Flex)`
  align-items: center;
  justify-content: center;
  max-width: ${({ theme }) => theme.qrCode.maxWidth};
  padding: ${({ theme }) => theme.fixedSizes.spacing_50};
  width: 100%;
`

const QRCodeContainerWrapper = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radii.lg};
  padding: ${({ theme }) => theme.fixedSizes.spacing_50};
`

const QRCodeText = styled(SmallText)`
  margin-bottom: ${({ theme }) => theme.fixedSizes.spacing_75};
`
