// This file should be edited under:
// <root>/configuration/environment/config.ts
//
// all ${VARIABLES} will be replaced before deploy
export const Config = {
  E2E: false,
  ENVIRONMENT: 'production',
  MOCK_STATE: '',
  BRANCH_KEY: 'key_live_dpGrjMykr9I9yE5z8qLijlidwunrt5R2',
  STRIPE_APIKEY:
    'pk_live_51HrTdzIDw3UYhiN0QtzBEiRiBB86MTLuS3P3lkzA3leS00nXNEvTjWM1JXyJxp5kQhL0LAFOSCaewLwaDVk6JHZh00njhhIKKZ',
  DISABLE_RECAPTCHA: false,
  AMPLITUDE_API_KEY: 'ed24d6862919affc99f119703fea8ceb',
  AMPLITUDE_DEPLOYMENT_KEY: 'client-LYnQKPoH9VaTloPw5FcFhEOIf58MYoWy',
  GTM_CONTAINER_ID: 'GTM-M65H4245',
  EMBED_ORIGINS_WHITELIST: [
    'securecafenet.com',
    'securecafe.com',
    'rentcafe.com',
    'securercqatolnet.com',
    'securercqatol.com',
    'rcqatol.com',
  ],
  CONSENT_MGMT_SRC: undefined,

  // Datadog Config
  DATADOG_ENV: 'prod',
  DATADOG_SITE: 'datadoghq.com',
  DATADOG_SERVICE_NAME: 'production-flex-consumer-web-app',
  DATADOG_APPLICATION_ID: '5e450cc4-e11a-40d3-a2f4-a5ecfb5b7755',
  DATADOG_CLIENT_TOKEN: 'pub81e29f28978a41d453a4b638a4266041',
  DATADOG_ALLOWED_TRACING_URLS: [/https:\/\/.*\.getflex\.com/],
  DATADOG_TRACE_SAMPLE_RATE: 5,
  VITE_BUILD_VERSION: import.meta.env.VITE_BUILD_VERSION,
  ITERABLE_API_KEY: 'fbebdbdaa0f34720a557cb1321016050',

  // Change to https://api.prod.getflex.com at some point.
  // See https://getflex.atlassian.net/browse/INFRA-1901
  FLEX_2_API: 'https://api.production-6385.getflex.com',

  // Change to https://growth-infra.prod.getflex.com at some point.
  // See https://getflex.atlassian.net/browse/INFRA-1902
  GROWTH_INFRA_API: 'https://growth-infra.production-6385.getflex.com',

  LOG_LEVEL: [],
  SAMPLE_RATE: 10,
}

export const FirebaseConfig = {
  apiKey: 'AIzaSyDuKr7tEW-0ZxuaI-UqgdvdcB_YHBlGkkQ',
  authDomain: 'getflex-production.firebaseapp.com',
  databaseURL: 'https://getflex-production.firebaseio.com',
  projectId: 'getflex-production',
  storageBucket: 'getflex-production.appspot.com',
  messagingSenderId: '962928635842',
  appId: '1:962928635842:web:1d894a40caf0315b48305b',
  measurementId: 'G-4E0VMTZM45',
}
