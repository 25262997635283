import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { OfferStates, OnboardingStep, OnboardingStepStatus } from '@genoa/middle-end'
import { useRestartOnboarding } from '@genoa/middle-end'

import { useAuthState } from '../../../../contexts'
import {
  useHandleNavigationForCreditBuilderValueProp,
  useIsEmbed,
  useShouldRestartEmbedOnboarding,
} from '../../../../hooks'
import { useHandleGetOnboardingStatus } from '../../../../hooks/flex2/onboarding-status'
import { useOfferState, useOnboardingStatusState } from '../../../../modules/flex2/use-state'
import * as Routes from '../../../../routing/constants'
import { FullScreenSpinnerLoading } from '../../../components/SpinnerLoading/FullScreenSpinnerLoading'
import { CreateAccountContainer } from '../account-creation/CreateAccountContainer'
import { UserAccountContent } from '../account-creation/types'
import { ResumeOnboardingToStepContainer } from './ResumeOnboardingToStepContainer'

export const OnboardingResumeOnboardingContainer = () => {
  const { navigateNext } = useHandleNavigationForCreditBuilderValueProp({ source: 'create' })
  const navigate = useNavigate()
  const onboardingStatusState = useOnboardingStatusState()
  const offerState = useOfferState()
  const goToFallback = () => navigate(Routes.Onboarding.ADDRESS)
  const { uid } = useAuthState()
  const { handleGetOnboardingStatus, loadingOnboardingStatus } = useHandleGetOnboardingStatus()
  const isEmbed = useIsEmbed()
  const [_, restartOnboarding] = useRestartOnboarding()
  const shouldRestartEmbedOnboarding = useShouldRestartEmbedOnboarding()

  useEffect(() => {
    if (uid) {
      if (isEmbed && shouldRestartEmbedOnboarding) {
        restartOnboarding({ customerPublicId: uid })
      }
      handleGetOnboardingStatus(uid, 'treatment')
    }
  }, [uid, isEmbed, shouldRestartEmbedOnboarding])

  const resolverResumeState = useCallback(
    (rentEstimationCompleted: boolean) => {
      const offer = offerState?.offer
      if (!offer) {
        return
      }
      if (
        rentEstimationCompleted &&
        (offer.offer_state === OfferStates.EXPIRED || offer.withdrawal_reason === 'Expiration')
      ) {
        return OfferStates.EXPIRED.toString()
      }
    },
    [offerState?.offer]
  )

  if (!onboardingStatusState.loaded || loadingOnboardingStatus) {
    return <FullScreenSpinnerLoading />
  }

  const accountCreationCompleted = onboardingStatusState.steps.find(
    (step) => step.step === OnboardingStep.ACCOUNT_CREATION && step.status === OnboardingStepStatus.COMPLETE
  )
  const rentEstimationCompleted = onboardingStatusState.steps.find(
    (step) => step.step === OnboardingStep.RENT_ESTIMATION && step.status === OnboardingStepStatus.COMPLETE
  )

  if (accountCreationCompleted) {
    return (
      <ResumeOnboardingToStepContainer
        analyticsScreenName={Analytics.Screens.SIGNUP_RESUME_ONBOARDING}
        goToFallback={goToFallback}
        resumeState={resolverResumeState(!!rentEstimationCompleted)}
      />
    )
  }

  return (
    <CreateAccountContainer
      hideTerms
      headerText={UserAccountContent.confirm.headerText}
      subheaderText={UserAccountContent.confirm.subheaderText}
      submitButtonText={UserAccountContent.confirm.submitButtonText}
      analyticsScreenName={Analytics.Screens.CONFIRM_ACCOUNT}
      firstNameEditEvent={Analytics.Events.CONFIRM_ACCOUNT_FIRST_NAME_EDIT}
      lastNameEditEvent={Analytics.Events.CONFIRM_ACCOUNT_LAST_NAME_EDIT}
      emailEditEvent={Analytics.Events.CONFIRM_ACCOUNT_EMAIL_EDIT}
      termsOfServiceClickedEvent={Analytics.Events.CONFIRM_TERMS_OF_SERVICE_CLICKED}
      privacyPolicyClickedEvent={Analytics.Events.CONFIRM_PRIVACY_POLICY_CLICKED}
      privacyNoticeClickedEvent={Analytics.Events.CONFIRM_PRIVACY_NOTICE_CLICKED}
      errorOnCTAEvent={Analytics.Events.CONFIRM_ERROR_ON_CTA}
      accountCreationClickedEvent={Analytics.Events.CONFIRM_ACCOUNT_CREATION_CTA_CLICKED}
      emailAlreadyInUseEvent={Analytics.Events.CONFIRM_EMAIL_ALREADY_IN_USE}
      onNext={navigateNext}
    />
  )
}
