import { useCallback } from 'react'
import { Analytics } from '@genoa/analytics'
import { FlexLinks } from '@genoa/domain'
import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useEmbedFlow } from '../../../hooks'
import { PhoneNumberState } from '../../../modules'
import { useAnalytics, useHelpLinks } from '../../../providers'
import { Headline2, InlineButton, PrimaryButton, SmallText } from '../../components'
import { PhoneInput } from '../../components/Input'
import { BasePageLayout } from '../../layouts'
import { ConfirmSMSTextContainer } from './confirm-sms-text/ConfirmSMSTextContainer'
import { ExperimentPhoneOpener } from './ExperimentPhoneOpener'

const subtext = `We’ll send a text message to this number to verify it’s yours.`
const modifiedSubtext =
  'We’ll send you a text message to confirm your identity and get you one step closer to worry-free rent.'

type PhoneNumberProps = {
  phone: string
  onPhoneChange: (phoneNumber: PhoneNumberState) => unknown
  onNextClick: () => unknown
  onDontHaveAnAccount: () => void
  modifyOpener?: boolean
  modifySubtext?: boolean
  autoComplete?: boolean
  showDontHaveAnAccount?: boolean
  coBrandingProperty?: string
  loading?: boolean
}

const PHONE_NUMBER_DIGITS = 10
const removeMask = (phone: string) => phone.replace(/\D/g, '').substring(0, PHONE_NUMBER_DIGITS)
const maskPhone = (phone: string) => {
  return removeMask(phone)
    .replace(/(\d{3})(\d)/, '($1) $2')
    .replace(/(\d{3})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1')
}

export const PhoneNumber = (props: PhoneNumberProps) => {
  const unmaskedPhone = removeMask(props.phone)
  const formattedPhone = maskPhone(unmaskedPhone)
  const analytics = useAnalytics()
  const helpLinks = useHelpLinks()
  const embedFlow = useEmbedFlow()

  const handlePhoneChange = useCallback(
    (newPhone: string) => {
      const unmaskedNewPhone = removeMask(newPhone)

      if (unmaskedPhone !== unmaskedNewPhone) {
        const formattedNewPhone = maskPhone(unmaskedNewPhone)
        props.onPhoneChange({
          extracted: unmaskedNewPhone,
          formatted: formattedNewPhone,
        })
      }
    },
    [unmaskedPhone, props.onPhoneChange]
  )
  const handleOnClick = useCallback(() => props.onNextClick(), [props.onNextClick])

  const handleIHaveNewPhoneNumber = () => {
    analytics.logEvent(Analytics.Events.NEW_PHONE_NUMBER_CTA_CLICKED)
    helpLinks.open(FlexLinks.phoneNumberFAQ)
  }

  return (
    <BasePageLayout
      analyticsScreenName={Analytics.Screens.SIGNIN_PHONE_NUMBER}
      analyticsScreenParams={{ embedFlow }}
      coBrandingProperty={props.coBrandingProperty}
    >
      <Box minH="30px" />

      {props.modifyOpener && <ExperimentPhoneOpener />}

      <Headline2>What&rsquo;s your number?</Headline2>

      <Box minH="15px" />

      <SmallText>{props.modifySubtext ? modifiedSubtext : subtext}</SmallText>

      <Box minH="30px" />

      <SmallText>Mobile number</SmallText>

      <Box minH="5px" />

      <PhoneInput
        phone={formattedPhone}
        onPhoneChange={handlePhoneChange}
        testID="AuthPhoneInput"
        autoComplete="phone"
      />

      <Box minH="17px" />
      <InlineButton onClick={handleIHaveNewPhoneNumber} fontSize={'14px'}>
        <b>I have a new phone number</b>
      </InlineButton>
      <Box minH="20px" />

      <Box>
        {props.showDontHaveAnAccount && (
          <Box textAlign="center">
            <SmallText>
              <InlineButton testID="RentAmountLogin" onClick={props.onDontHaveAnAccount}>
                Don&rsquo;t have an account yet? <CreateAccountText>Create an account</CreateAccountText>
              </InlineButton>
            </SmallText>
          </Box>
        )}
        <Box minH="32px" />

        <ConfirmSMSTextContainer />

        <Box minH="30px" />

        <PrimaryButton
          disabled={unmaskedPhone.length !== 10 || props.loading}
          processing={props.loading}
          onClick={handleOnClick}
          testID="AuthNextButton"
        >
          Send verification code
        </PrimaryButton>
      </Box>
    </BasePageLayout>
  )
}

const CreateAccountText = styled(SmallText)`
  text-align: center;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  color: ${(props) => props.theme.colors.jewelPurple};
`
